import { template as template_c3a5072e49f045158e07bb094f8770e9 } from "@ember/template-compiler";
const FKLabel = template_c3a5072e49f045158e07bb094f8770e9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
