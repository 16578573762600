import { template as template_04ba333f74154656882d81208d5d95ec } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { modifier } from "ember-modifier";
import { eq } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
export default class DBreadcrumbsContainer extends Component {
    @service
    breadcrumbs;
    registerContainer = modifier((element)=>{
        const container = {
            element
        };
        this.breadcrumbs.containers.add(container);
        return ()=>this.breadcrumbs.containers.delete(container);
    });
    get lastItemIndex() {
        return this.breadcrumbs.items.size - 1;
    }
    static{
        template_04ba333f74154656882d81208d5d95ec(`
    <ul {{this.registerContainer}} class="d-breadcrumbs" ...attributes>
      {{#each this.breadcrumbs.items as |item index|}}
        {{#let item.templateForContainer as |Template|}}
          <Template
            @linkClass={{concatClass
              "d-breadcrumbs__link"
              @additionalLinkClasses
            }}
            aria-current={{if (eq index this.lastItemIndex) "page"}}
            class={{concatClass "d-breadcrumbs__item" @additionalItemClasses}}
          />
        {{/let}}
      {{/each}}
    </ul>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
