import { template as template_5a90ba3256344918b679d91f8b3b721c } from "@ember/template-compiler";
const FKText = template_5a90ba3256344918b679d91f8b3b721c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
